<template>
  <b-card-code>
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Rendelések</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button
          class="btn btn-primary mr-0 mr-md-2"
          @click="$router.push( {name:'monolit.order.list.archive'} )"
        >
          Archivált
        </button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'inside_order'"
        >
          <span v-if="props.row.inside_order == 1">Belsős rendelés</span>
          <span v-else-if="props.row.inside_order == 0">Külsős rendelés</span>
          <span v-else-if="props.row.inside_order == 2">Raktár feltöltés</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted'"
        >
          <span
            v-if="props.row.accepted == true"
            class="badge badge-success"
            style=";width:150px;color:white"
          >Elfogadva</span>
          <span
            v-else-if="props.row.disabled == true"
            class="badge badge-danger"
            style=";width:150px;color:white"
          >Elutasítva</span>
          <span
            v-else
            class="badge badge-warning"
            style=";width:150px;color:white"
          >Feldolgozás alatt</span>
        </div>

        <div
          v-else-if="props.column.field === 'accepted_warehouse'"
        >
          <div v-if="props.row.inside_order !== 2">
            <span
              v-if="props.row.accepted_warehouse === true"
              class="badge badge-success"
              style=";width:150px;color:white"
            >Kiküldve</span>
            <span
              v-else-if="props.row.disabled_warehouse === true"
              class="badge badge-danger"
              style=";width:150px;color:white"
            >Elutasítva</span>
            <span
              v-else
              class="badge badge-warning"
              style=";width:150px;color:white"
            >Feldolgozás alatt</span>
          </div>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            style=";width:150px;color:white"
            :variant="statusVariant(props.row.status)"
          >
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-edit
                @click="loadOrderData(props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Feldolgozás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="changeArchiveStatus(props.row.id)">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
                <span>Archiválás</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-in-offer"
      ref="modal-in-offer"
      size="lg"
      title="Megrendelőlap generálás"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-6">
          <b-form-group
            label="Melyik cégtől szeretnéd rendelni?"
            label-for="order_company"
          >
            <b-form-select
              id="order_company"
              v-model="order_company"
              :options="order_companyOptions"
              text-field="value"
              value-field="id"
            />
          </b-form-group>
        </div>
        <div
          v-if="order_company === 0"
          class="col-lg-6 row"
        >
          <b-form-group
            label="Cég adószáma"
            label-for="order_company_tax_number"
            class="col-lg-10"
          >
            <b-form-input
              id="order_company_tax_number"
              v-model="order_company_tax_number"
            />
          </b-form-group>
          <div class="col-2 col-md-1">
            <br/>
            <button @click="checkTaxNumber" class="btn btn-primary">
              <feather-icon
                icon="SearchIcon"
                size="14"
              />
            </button>
          </div>
        </div>
        <div
          v-if="order_company === 0"
          class="col-lg-6"
        >
          <b-form-group
            label="Cég neve"
            label-for="order_company_name"
          >
            <b-form-input
              id="order_company_name"
              v-model="order_company_name"
            />
          </b-form-group>
        </div>
        <div
          v-if="order_company === 0"
          class="col-lg-6"
        >
          <b-form-group
            label="Cég címe"
            label-for="order_company_address"
          >
            <b-form-input
              id="order_company_address"
              v-model="order_company_address"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Melyik céggel szeretnéd megrendelni?"
            label-for="own_company"
          >
            <b-form-select
              id="own_company"
              v-model="own_company"
              :options="own_companyOptions"
              text-field="value"
              value-field="id"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Rendelés dátuma"
            label-for="order_date"
          >
            <b-form-input
              id="order_date"
              v-model="order_date"
              type="date" max="9999-12-31"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Melyik projekzhez tartozik?"
            label-for="order_projekt"
          >
            <b-form-select
              id="order_projekt"
              v-model="order_projekt"
              :options="order_projektOptions"
              text-field="text"
              value-field="value"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Átvevő?"
            label-for="order_receiver"
          >
            <b-form-select
              id="order_receiver"
              v-model="order_receiver"
              :options="order_receiverOptions"
              text-field="name"
              value-field="id"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Átvétel helye"
            label-for="order_place_of_receipt"
          >
            <b-form-input
              id="order_place_of_receipt"
              v-model="order_place_of_receipt"
            />
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group
            label="Szállítás módja?"
            label-for="order_delivery_method"
          >
            <b-form-select
              id="order_delivery_method"
              v-model="order_delivery_method"
              :options="order_delivery_methodOptions"
              text-field="name"
              value-field="id"
            />
          </b-form-group>
        </div>
        <div class="row border-bottom-own ml-0 mr-0">
          <div class="col-lg-12 mb-1">
            <b>Termék hozzáadása</b>
          </div>
          <div class="col-lg-4 text-light">
            <b-form-group
              label="Termék megnevezése"
              label-for="order_name"
            >
              <vue-autosuggest
                v-model="order_name"
                :suggestions="filteredOptions2"
                :limit="10"
                :input-props="{id:'autosuggest__input2',class:'form-control', placeholder:'Termék neve'}"
                @selected="setProduct"
                @input="onInputChange2"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.product }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </div>
          <div class="col-lg-3 text-light">
            <b-form-group
              label="Mennyiség"
              label-for="order_qty"
            >
              <b-form-input
                v-model="order_qty"
                placeholder="Mennyiség"
                type="number"
              />
            </b-form-group>
          </div>
          <div class="col-lg-3 text-light">
            <b-form-group
              label="Egység"
              label-for="order_unit"
            >
              <vue-autosuggest
                v-model="order_unit"
                :suggestions="filteredOptions"
                :limit="10"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Mennyiségi egység'}"
                @selected="setUnit"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.supplier }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </div>
          <div class="col-lg-3 text-light">
            <b-form-group
              label="Egységár"
              label-for="order_qty_price"
            >
              <b-form-input
                v-model="order_qty_price"
                placeholder="Egységár"
                type="text"
              />
            </b-form-group>
          </div>
          <div class="col-lg-4 text-light">
            <b-form-group
              label="Érték"
              label-for="order_price"
            >
              <b-form-input
                v-model="order_price"
                placeholder="Érték"
                type="text"
              />
            </b-form-group>
          </div>
          <div class="col-lg-3 text-light">
            <br>
            <button
              class="btn btn-primary"
              @click="addProductToOrder()"
            >
              Rögzítés
            </button>
          </div>
          <div
            v-if="order_file.length > 0"
            class="col-lg-12 mt-1"
          >
            <table class="table">
              <tr>
                <th>Termék neve</th>
                <th>Mennyiség</th>
                <th>Egység</th>
                <th>Egységár</th>
                <th>Érték</th>
                <th />
              </tr>
              <tr
                v-for="(product, index) in order_file"
                :key="index"
              >
                <td>{{ product.name }}</td>
                <td><input
                  v-model="product.qty"
                  class="form-control"
                  type="number"
                  @keyup="setPriceTotal(index)"
                ></td>
                <td>{{ product.unit }}</td>
                <td><input
                  v-model="product.unit_price"
                  class="form-control"
                  type="number"
                  @keyup="setPriceTotal(index)"
                ></td>
                <td>{{ product.total_price }} Ft</td>
                <td>
                  <span
                    class="badge badge-danger"
                    style="cursor:pointer;color:white"
                  ><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                    @click="removeProductFromOrder(index)"
                  /></span>
                </td>
              </tr>
            </table>
          </div>
          <div
            v-if="order_file.length > 0"
            class="col-lg-12 mb-2"
          >
            <button
              class="btn btn-primary float-right"
              @click="createOrderDocument()"
            >
              Generálás
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-edit"
      ref="modal-edit"
      size="lg"
      title="Rendelés adatai"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0">
                Megrendelő neve: {{ selected.creator_name }}
              </p>
              <p class="mb-0">
                Projekt: {{ selected.project_name }}
              </p>
              <p class="mb-0" v-if="selected.inside_order !== 2">
                Szállítás helye: {{ selected.place }}
              </p>
              <p class="mb-0" v-if="selected.inside_order !== 2">
                Rendelést átveszi: {{ selected.receiver_name }}
              </p>
              <p class="mb-0" v-if="selected.inside_order !== 2">
                Mikorra szeretné: {{ selected.order_date }}
              </p>
              <p
                v-if="selected.order_deliver_started"
                class="mb-0"
              >
                Várható kiszállítás: {{ selected.order_deliver_started_date }}
              </p>
              <p class="mb-0">
                Megjegyzés: {{ selected.comment }}
              </p>
            </div>
            <div
              v-show="selected.inside_order == 0"
              class="col-lg-6"
            >
              <div v-if="checkID(selected.target_id)">
                <b-form-group
                  label="Aktuális felelős"
                  label-for="order-project"
                >
                  <b-form-select
                    id="order-project"
                    v-model="target_id"
                    :options="targetOptions"
                    text-field="name"
                    value-field="id"
                  />
                </b-form-group>
                <p class="float-right">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="changeTargetID()"
                  >
                    Módosítás
                  </button>
                </p>
              </div>
              <div v-else>Aktuális felelős<br><span class="badge badge-info">{{ selected.target_name }}</span>
              </div>
            </div>
          </div>

          <p class="mt-3">
            <b>Rendelt termékek</b>
          </p>
          <div id="print_div">
            <table class="table mb-2">
              <thead>
                <tr>
                  <th scope="col">
                    Termék
                  </th>
                  <th scope="col">
                    Mennyiség
                  </th>
                  <th scope="col">
                    Mennyiségi egység
                  </th>
                  <th
                    v-if="selected.accepted == true && selected.accepted_warehouse == true"
                    class="text-center"
                    scope="col"
                  >
                    Teljesítve
                  </th>
                  <th
                    v-if="selected.accepted == false && selected.accepted_warehouse == true && selected.inside_order == 2"
                    class="text-center"
                    scope="col"
                  >
                    Teljesítve
                  </th>
                </tr>
              </thead>
              <tr
                v-for="(product, index) in selected.items"
                :key="index"
              >
                <td>
                  {{ product.product }}
                </td>
                <td>
                  {{ product.quantity }}
                </td>
                <td>
                  {{ product.unit }}
                </td>
                <td
                  v-if="selected.accepted == true && selected.accepted_warehouse == true && (selected.inside_order == 0 || selected.inside_order == 1)"
                  class="text-center"
                >
                  <span
                    v-if="product.done"
                    class="badge badge-success"
                    style="cursor:pointer"
                  ><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span
                    v-else
                    class="badge badge-danger"
                    style="cursor:pointer;max-width:150px;color:white"
                  ><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </td>
                <td class="text-center" v-if="selected.accepted_warehouse == true && selected.inside_order == 2">
                  <div v-if="selected.accepted">
                    <span class="badge badge-success" style="cursor:pointer" v-if="product.done"><feather-icon
                      icon="CheckCircleIcon"
                      size="18"
                    /></span>
                    <span class="badge badge-danger" style="cursor:pointer" v-else><feather-icon
                      icon="MinusCircleIcon"
                      size="18"
                    /></span>
                  </div>
                  <div v-else>
                    <span class="badge badge-success" style="cursor:pointer" @click="changeItemStatusPrivate(index)" v-if="product.done"><feather-icon
                      icon="CheckCircleIcon"
                      size="18"
                    /></span>
                    <span class="badge badge-danger" style="cursor:pointer" @click="changeItemStatusPrivate(index)" v-else><feather-icon
                      icon="MinusCircleIcon"
                      size="18"
                    /></span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-lg-12 row justify-content-end">
            <!--<button class="btn btn-primary" v-if="(selected.inside_order == 2 && selected.accepted == 0 && checkRole('storage_role'))" @click="notificationNewProduct()">Értesítés az elfogadott termékekről</button>-->
            <button class="btn btn-primary" style="float:right" v-if="(selected.inside_order == 2 && selected.accepted == 0) && checkRole('storage_role')" @click="changeItemStatusPrivateAll()">Összes elfogadása</button>
            <button
              v-if="selected.inside_order != 2"
              class="btn btn-primary"
              @click="print()"
            >
              Rendelt termékek nyomtatása
            </button>
          </div>
          <div v-show="selected.inside_order == 0">
            <p class="mt-5 pt-5">
              <b>Árajánlatok</b>
            </p>
            <div class="row">
              <div class="col-lg-2">
                Cég név
              </div>
              <div class="col-lg-3">
                Dátum
              </div>
              <div class="col-lg-2 text-right">
                Ár
              </div>
              <div class="col-lg-3">
                Rögzítette
              </div>
              <div class="col-lg-2">
                Elfogadás
              </div>
            </div>
            <hr>
            <div
              v-for="(file, index) in selected.files"
              v-if="selected.files"
              :key="index"
              class="row pt-1 pb-1"
            >
              <div class="col-lg-2">
                {{ file.company_name }}
              </div>
              <div class="col-lg-3">
                {{ file.created_at }}
              </div>
              <div class="col-lg-2 text-right">
                {{ parseInt(file.company_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </div>
              <div class="col-lg-3">
                {{ file.creator_name }}
              </div>
              <div
                v-if="!file.accepted && !file.declined"
                class="col-lg-2"
              >
                <div v-if="checkRole('accept_order')">
                  <span
                    class="badge badge-success mr-1"
                    style="cursor:pointer"
                    @click="changeOfferStatus(index, true)"
                  ><feather-icon
                    icon="CheckCircleIcon"
                    size="18"
                  /></span>
                  <span
                    class="badge badge-danger"
                    style="cursor:pointer"
                    @click="changeOfferStatus(index, false)"
                  ><feather-icon
                    icon="MinusCircleIcon"
                    size="18"
                  /></span>
                </div>
                <div v-else>
                  <span class="badge badge-info">
                    Feldolgozás alatt
                  </span>
                </div>
              </div>
              <div
                v-else
                class="col-lg-2"
              >
                <span
                  v-if="file.accepted"
                  class="badge badge-success"
                >Elfogadva</span>
                <span
                  v-else
                  class="badge badge-danger"
                >Elutasítva</span>
              </div>
              <div class="col-lg-12 pr-1 pl-1 pt-1">
                <div class="bg-light-primary pt-1 pl-1 pr-1 pb-1">
                  <div class="row border-bottom-own ml-0 mr-0">
                    <div class="col-lg-5 font-weight-bold text-light">
                      Termék neve
                    </div>
                    <div class="col-lg-3 font-weight-bold text-light">
                      Szükséges
                    </div>
                    <div class="col-lg-3 font-weight-bold text-light">
                      Elérhető
                    </div>
                  </div>
                  <div
                    v-for="offer in file.offer_items"
                    :key="offer.product"
                    class="row border-bottom-own ml-0 mr-0"
                  >
                    <div class="col-lg-5 text-light">
                      {{ offer.product }}
                    </div>
                    <div class="col-lg-3 text-light">
                      {{ offer.quantity }} {{ offer.unit }}
                    </div>
                    <div class="col-lg-3 text-light">
                      {{ offer.available }} {{ offer.unit }}
                    </div>
                  </div>
                </div>
                <button
                  v-if="file.path && file.path != null"
                  class="float-right btn btn-primary btn-sm mt-2 mr-2"
                  @click="downloadFile(selected.id, index)"
                >
                  Ajánlat letöltése
                </button>
                <a
                  v-if="file.email && file.email != null"
                  class="float-right btn btn-primary btn-sm mt-2 mr-2"
                  target="_blank"
                  :href="file.email"
                >Email megtekintése</a>
                <button
                  v-if="file.accepted && file.path_generated == null"
                  class="float-right btn btn-primary btn-sm mt-2 mr-2"
                  @click="selectedFile(index, file.offer_items, file, selected.product_id)"
                >
                  Megrendelőlap készítés
                </button>
                <button
                  v-if="file.accepted && file.path_generated != null"
                  class="float-right btn btn-primary btn-sm mt-2 mr-2"
                  @click="selectedFile(index, file.offer_items, file, selected.product_id)"
                >
                  Megrendelőlap újra generálás
                </button>
                <button
                  v-if="file.accepted && file.path_generated != null"
                  class="float-right btn btn-primary btn-sm mt-2 mr-2"
                  @click="downloadPDF(index)"
                >
                  Megrendelőlap letöltése
                </button>
              </div>
            </div>
            <div v-else>
              <div>Még nem érkezett ajánlat</div>
            </div>
            <p class="mt-3">
              <b>Árajánlatok rögzítése</b>
            </p>
            <div
              v-if="checkRole('upload_offer')"
              class="row pr-1 pl-1"
            >
              <b-form-input
                v-model="company_name"
                class="col-md-4 mr-1"
                type="text"
                placeholder="Cég neve"
              />
              <b-form-input
                v-model="company_price"
                class="col-md-2 mr-1"
                type="number"
                placeholder="Ára"
              />
              <b-form-file
                id="file"
                ref="file"
                v-model="file"
                class="col-md-3 mr-1"
                type="file"
                @change="changeFile"
              />
              <button
                class="btn btn-primary mb-2 col-md-2"
                @click="addFile(selected.id)"
              >
                Feltöltés
              </button>
              <b-form-input
                v-model="email"
                class="col-md-12 mb-1"
                type="text"
                placeholder="Email az ajánlatról"
              />
              <table class="table col-lg-12">
                <thead>
                  <tr>
                    <th scope="col">
                      Termék
                    </th>
                    <th scope="col">
                      Szükséges
                    </th>
                    <th scope="col">
                      Rendelhető mennyiség
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(offer, index) in offerItems"
                    :key="index"
                  >
                    <td>
                      {{ offer.product }}
                    </td>
                    <td>
                      {{ offer.quantity }} {{ offer.unit }}
                    </td>
                    <td>
                      <input
                        :id="'availableOffer'+index"
                        v-model="offer.available"
                        type="text"
                        placeholder="Elérhető"
                        class="form-control text-center"
                      >
                    </td>
                    <td>
                      <b-form-checkbox
                        v-model="offer.inOffer"
                        @change="setQty(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <b-row v-if="materials.length > 0">
            <b-col md="12" class="mt-3"><b>Elérhető tételek a projekthez</b></b-col>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Termék</th>
                  <th scope="col">Mennyiség (elérhető / összesen)</th>
                  <th scope="col">Mértékegység</th>
                </tr>
              </thead>
              <tr v-for="(material, index) in materials" :key="index">
                <td>
                  {{ material.product }}
                </td>
                <td>
                  {{ material.quantity }} / {{ material.total }}
                </td>
                <td>
                  {{ material.unit }}
                </td>
              </tr>
            </table>
          </b-row>
          <p class="mt-3">
            <b>Megjegyzések</b>
          </p>
          <b-form-group
            label="Megjegyzés írása"
            label-for="write-comment"
          >
            <b-form-textarea
              id="write-comment"
              v-model="new_comment"
            />
          </b-form-group>
          <button
            class="btn btn-primary mb-2"
            @click="addComment(selected.id)"
          >
            Küldés
          </button>
          <hr>
          <div
            v-for="(comment, index) in selected.comment_array"
            :key="index"
          >
            <p><b>{{ comment.user }}</b><br><small>{{ comment.date }}</small><br>{{ comment.comment }}</p>
            <hr>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div>
          <button
            class="mr-2 btn"
            @click="$bvModal.hide('modal-edit')"
          >
            Bezár
          </button>
        </div>
        <div>
          <button
            v-if="checkRole('decline_offer') && !selected.accepted && !selected.disabled && selected.review_order"
            class="mr-2 btn btn-danger"
            @click="disabled(selected.id)"
          >
            Nem vesszük meg
          </button>
          <button
            v-if="checkRole('upload_offer') && !selected.review_order && selected.inside_order == 0 && !selected.accepted"
            class="mr-2 btn btn-primary"
            @click="sendOfferToReview(selected.id)"
          >
            Értesítés az ajánlatokról
          </button>
          <button
            v-if="checkRole('decline_offer') && !selected.accepted && !selected.disabled && selected.review_order"
            class="mr-2 btn btn-primary"
            @click="sendAlternative(selected.id)"
          >
            Kérjünk be alternatívát
          </button>
          <button
            v-if="checkRole('decline_offer') && !selected.accepted && !selected.disabled && selected.review_order"
            class="mr-2 btn btn-primary"
            @click="searchOtherCompany(selected.id)"
          >
            Máshonnan veszünk
          </button>
          <button
            v-if="checkRole('decline_offer') && !selected.accepted && !selected.disabled && selected.review_order"
            class="mr-2 btn btn-primary"
            @click="accepted_boss(selected.id)"
          >
            Elfogadva
          </button>
          <button
            v-if="checkRole('delivery_offer') && selected.accepted && selected.inside_order == 1 && !selected.order_deliver_started"
            class="mr-2 btn btn-primary"
            @click="acceptAddDate(selected.id)"
          >
            Értesítés a várható kiszállításról
          </button>
          <button
            v-if="checkRole('delivery_offer') && selected.accepted && selected.inside_order == 1 && selected.order_deliver_started"
            class="mr-2 btn btn-primary"
            @click="accepted(selected.id)"
          >
            Kiszállítva
          </button>
          <button
            v-if="selected.accepted && selected.inside_order == 0 && !selected.status != 'Teljesítve'"
            class="mr-2 btn btn-primary"
            @click="acceptedOutside(selected.id)"
          >
            Értesítés, hogy a kiszállítás folyamatban
          </button>
          <button
            v-if="!selected.accepted && selected.status == 'Feldolgozás alatt' && selected.inside_order == 2 && checkRole('storage_role')"
            class="mr-2 btn btn-primary"
            @click="acceptedStorage(selected.id)"
          >
            Készlet feltöltés elfogadása és lezárása
          </button>
        </div>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormGroup, BFormFile, BFormTextarea, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { VueAutosuggest } from 'vue-autosuggest'
import Swal from 'sweetalert2'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    VueAutosuggest,
  },
  data() {
    return {
      materials: [],
      project_list: [],
      order_company_name: '',
      order_company_address: '',
      order_company_tax_number: '',
      order_receiver: '',
      order_receiverOptions: [],
      order_companyOptions: [{ id: 0, value: 'Új rögzítése' }],
      order_company: '',
      own_companyOptions: [{ id: 'MP', value: 'Monolit Profi Kft.' }, { id: 'ME', value: 'Monolit Épszer Kft.' }],
      own_company: '',
      order_projektOptions: [],
      order_projekt: '',
      order_date: '',
      order_name: '',
      order_qty: '',
      order_unit: '',
      order_qty_price: '',
      order_price: '',
      email: '',
      company_name: '',
      company_price: '',
      offerItems: [],
      order_delivery_method: '',
      order_delivery_methodOptions: [{ name: 'Szállítás igénylése', id: 'Szállítás igénylése' }, { name: 'Szállítást vállaljuk', id: 'Szállítást vállaljuk' }],
      selected: [],
      order_place_of_receipt: '',
      datasuggest2: [],
      filteredOptions: [],
      filteredOptions2: [],
      generated_file_index: 0,
      datasuggest: [],
      targetOptions: [],
      target_id: '',
      pageLength: 25,
      dir: false,
      offers: [],
      file: null,
      order_file: [],
      new_comment: '',
      columns: [
        {
          label: 'Rendelés típusára',
          field: 'inside_order',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Projekt neve',
          field: 'project_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés projektre',
          },
        },
        {
          label: 'Helyszín',
          field: 'place',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés helyszínre',
          },
        },
        {
          label: 'Árajánlat elfogadva',
          field: 'accepted',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés státuszra',
          },
        },
        {
          label: 'Rendelés kiküldve',
          field: 'accepted_warehouse',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés státuszra',
          },
        },
        {
          label: 'Ki szeretné?',
          field: 'creator_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Státusz',
          field: 'status',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadOrderList(this.$route.params.id)
      this.$bvModal.show('modal-edit')
    } else {
      this.loadOrderList(0)
    }
    this.getTargetOptions()
    this.loadProduct()
    this.loadSupplier()
    this.getProjectList()
    this.getUserOptions()
    this.getCompanyList()
  },
  methods: {
    checkTaxNumber() {
      axios({
        method: 'get',
        url: `project/task/check_tax_number/${this.order_company_tax_number}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.order_company_name = response.data[0][0]
        // eslint-disable-next-line prefer-destructuring
        this.order_company_address = response.data[1]
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs találat az adószámra',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    setPriceTotal(index) {
      this.order_file[index].total_price = this.order_file[index].unit_price * this.order_file[index].qty
    },
    setQty(index) {
      document.getElementById(`availableOffer${index}`).value = this.offerItems[index].quantity
      this.offerItems[index].available = this.offerItems[index].quantity
    },
    print() {
      const prtHtml = document.getElementById('print_div').innerHTML
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let selectedFiles = null

      if (this.selected.files != null) {
        selectedFiles = this.selected.files.filter(file => file.accepted === true)
      }

      axios({
        method: 'get',

        url: `order/update/status-started/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            body{
              font-size: 26px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              text-align:center;
            }
            .mb-0 {
              margin-bottom: 0px !important;
            }
          </style>
        </head>
        <body>
          <div style="background:gray;">
            <p class="mb-0">Megrendelő neve: ${this.selected.creator_name}</p>
            <p class="mb-0">Projekt: ${this.selected.project_name}</p>
            <p class="mb-0">Szállítás helye: ${this.selected.place}</p>
            <p class="mb-0">Rendelést átveszi: ${this.selected.receiver_name}</p>
            <p class="mb-0">Mikorra szeretné: ${this.selected.order_date}</p>
            <p class="mb-0">Megjegyzés: ${this.selected.comment}</p>
            <hr>
          </div>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    acceptedOutside(id) {
      axios({
        method: 'put',

        url: `order/warehouse/accept/outside/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
        this.$bvModal.hide('modal-edit')
      })
    },
    getCompanyList() {
      axios({
        method: 'get',

        url: 'order/company/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(project => {
          this.order_companyOptions.push({
            id: project.id,
            value: project.name,
          })
        })
      })
    },
    downloadPDF(index) {
      axios({
        method: 'post',

        url: `order/generate-order/download/${this.selected.id}/${index}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `rendelés_${this.selected.id}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
    },
    createOrderDocument() {
      axios({
        method: 'post',

        url: `order/generate-order/${this.selected.id}/${this.generated_file_index}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          company_id: this.order_company,
          company_name: this.order_company_name,
          company_address: this.order_company_address,
          company_tax_number: this.order_company_tax_number,
          monolit: this.own_company,
          order_date: this.order_date,
          project_id: this.order_projekt,
          receiver_id: this.order_receiver,
          place: this.order_place_of_receipt,
          delivery_method: this.order_delivery_method,
          orders: this.order_file,
        },
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `rendelés_${this.selected.id}.pdf`)
        document.body.appendChild(link)
        link.click()
        this.order_delivery_method = ''
        this.order_company = ''
        this.order_company_name = ''
        this.order_company_address = ''
        this.order_company_tax_number = ''
        this.order_receiver = ''
        this.order_projekt = ''
        this.order_date = ''
        this.order_place_of_receipt = ''
        this.order_file = []
        this.$bvModal.hide('modal-in-offer')
        this.loadOrderList(this.selected.id)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    notificationNewProduct() {
      axios({
        method: 'put',
        url: `order/notification/storage/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          items: this.selected.items,
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen értesítettük a raktárt',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    changeItemStatusPrivateAll() {
      this.selected.items.forEach(item => {
        item.done = true
      })
    },
    changeItemStatusPrivate(index) {
      this.selected.items[index].done = !this.selected.items[index].done
    },
    getUserOptions() {
      axios({
        method: 'get',

        url: 'user/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.order_receiverOptions = res.data
      })
    },
    getProjectList() {
      axios({
        method: 'get',

        url: 'project/list/all/for-order',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.order_projektOptions.push({
          value: 0,
          text: 'Nem tartozik projekthez',
        })
        res.data.forEach(project => {
          this.order_projektOptions.push({
            value: project.id,
            text: project.name,
          })
        })
        this.project_list = res.data
      })
    },
    setUnit(text) {
      this.unit = text.item.name
    },
    setProduct(text) {
      this.product = text.item.name
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.filter(item => item.supplier.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    onInputChange2(text) {
      if (text === '' || text === undefined) {
        return
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest2.filter(item => item.product.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions2 = [{
        data: filteredData,
      }]
    },
    loadSupplier() {
      axios({
        method: 'get',

        url: 'order/autocomplete/supplier',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(supplier => {
          this.datasuggest.push({
            supplier: supplier.supplier,
            name: supplier.supplier,
          })
        })
      })
    },
    loadProduct() {
      axios({
        method: 'get',

        url: 'order/autocomplete/product',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(product => {
          this.datasuggest2.push({
            product: product.product,
            name: product.product,
          })
        })
      })
    },
    addProductToOrder() {
      if (this.order_name == '' || this.order_qty == '' || this.order_unit == '' || this.order_qty_price == '' || this.order_price == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs megadva termék neve',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.order_file.push({
        name: this.order_name,
        qty: this.order_qty,
        unit: this.order_unit,
        unit_price: this.order_qty_price,
        total_price: this.order_price,
      })

      this.order_name = ''
      this.order_qty = ''
      this.order_unit = ''
      this.order_qty_price = ''
      this.order_price = ''
    },
    removeProductFromOrder(index) {
      this.order_file.splice(index, 1)
    },
    // eslint-disable-next-line camelcase
    selectedFile(index, offers, offer, product_id) {
      this.generated_file_index = index
      this.order_file = []
      console.log(offers)
      offers.forEach(element => {
        this.order_file.push({
          name: element.product,
          qty: element.available,
          unit: element.unit,
          unit_price: 0,
          total_price: 0,
        })
      })

      if (this.order_companyOptions.find(x => x.value === offer.company_name) !== undefined) {
        this.order_company = this.order_companyOptions.find(x => x.value === offer.company_name).id
      } else {
        this.order_company = 0
        this.order_company_name = offer.company_name
      }

      // eslint-disable-next-line camelcase
      if (product_id) {
        // eslint-disable-next-line camelcase
        var work_number = this.project_list.find(x => x.id === product_id).work_number

        // eslint-disable-next-line camelcase
        if (work_number) {
          if (work_number.slice(0, 2) === 'ME') {
            this.own_company = 'ME'
          } else {
            this.own_company = 'MP'
          }
        }

        // eslint-disable-next-line camelcase
        this.order_projekt = product_id
      }

      this.$bvModal.show('modal-in-offer')
    },
    sendAlternative() {
      axios({
        method: 'put',
        url: `order/send-alternative/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    searchOtherCompany() {
      axios({
        method: 'put',
        url: `order/search-other-company/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    sendOfferToReview() {
      axios({
        method: 'put',

        url: `order/send-offer-to-review/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeOfferData(index) {
      this.offerItems[index].inOffer = !this.offerItems[index].inOffer
    },
    checkID(id) {
      return JSON.parse(localStorage.getItem('user')).id === id
    },
    checkRole(roles) {
      return JSON.parse(localStorage.getItem('user')).role.includes(roles)
    },
    acceptedStorage(id) {
      axios({
        method: 'put',
        url: `order/storage/accept/${id}`,
        data: {
          items: this.selected.items,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(id)
        this.$bvModal.hide('modal-edit')
      })
    },
    accepted_boss() {
      axios({
        method: 'get',
        url: `order/decided/accept/${this.selected.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
        this.$bvModal.hide('modal-edit')
      })
    },
    downloadFile(id, index) {
      axios({
        method: 'post',

        url: `order/download-order/${id}/${index}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(response => {
        const mimeType = response.headers['content-type']
        const extension = mimeType.split('/')[1]
        const blob = new Blob([response.data], { type: mimeType })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `file.${extension}`
        link.click()
      })
    },
    changeItemStatus(index, status) {
      axios({
        method: 'put',

        url: `order/change_offer_type/${this.selected.id}/${index}`,
        data: {
          status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeFile(event) {
      this.file = event.target.files[0]
    },
    addFile() {
      const formData = new FormData()
      if (this.company_name === null || this.company_price === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs megadva a cég neve vagy az ár',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      }
      formData.append('file', this.file)
      formData.append('company_name', this.company_name)
      formData.append('company_price', this.company_price)
      formData.append('email', this.email)
      this.offerItems.forEach(item => {
        formData.append('offer_items[]', JSON.stringify(item))
      })
      axios({
        method: 'post',

        url: `order/upload-file/${this.selected.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen feltölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.file = null
        this.company_name = ''
        this.company_price = ''
        this.email = ''
        this.$refs.file.value = null
        this.loadOrderList(this.selected.id)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    changeTargetID() {
      axios({
        method: 'put',

        url: `order/change-target-id/${this.selected.id}`,
        data: {
          target_id: this.target_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    changeOfferStatus(index, status) {
      axios({
        method: 'put',

        url: `order/change_offer_type/${this.selected.id}/${index}`,
        data: {
          status,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(this.selected.id)
      })
    },
    getTargetOptions() {
      axios({
        method: 'get',

        url: 'role/list/order_target',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.targetOptions = res.data
      })
    },
    changeArchiveStatus(id) {
      axios({
        method: 'put',

        url: `order/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.loadOrderList(0)
      })
    },
    loadOrderList(id) {
      axios({
        method: 'get',

        url: 'order/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
        if (id !== 0) {
          this.selected = this.rows.find(item => item.id == id)
          this.target_id = this.selected.target_id
          this.offerItems = this.selected.items
          this.offerItems.forEach(item => {
            item.available = 0
            item.inOffer = false
          })
        }
      })
    },
    loadOrderData(id) {
      this.selected = this.rows.find(row => row.id === id)
      this.target_id = this.selected.target_id
      this.offerItems = this.selected.items
      this.offerItems.forEach(item => {
        item.available = 0
        item.inOffer = false
      })

      axios({
        method: 'get',
        url: `order/available-materials/${this.selected.product_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.materials = res.data
      })
    },
    disabled(id) {
      axios({
        method: 'get',

        url: `order/decided/disable/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
        this.$bvModal.hide('modal-edit')
      })
    },
    addComment(id) {
      if (this.new_comment == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Adjon meg kommentet',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        axios({
          method: 'post',

          url: `order/new-comment/${id}`,
          data: {
            comment: this.new_comment,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(response => {
          this.selected.comment_array = response.data
          this.new_comment = ''
        })
      }
    },
    acceptAddDate(id) {
      this.$bvModal.hide('modal-edit')
      Swal.fire({
        title: 'Mikorra várható a kiszállítás?',
        showDenyButton: false,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-danger mr-1',
        },
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Tovább',
        cancelButtonText: 'Mégsem',
      }).then(result => {
        if (result.isDenied) {
          this.$bvModal.show('modal-edit')
          return null
        }
        if (result.isConfirmed) {
          axios({
            method: 'post',

            url: `order/accept-order-started/${id}`,
            data: {
              text: result.value,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(() => {
            this.loadOrderList(0)
            this.$bvModal.hide('modal-edit')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeresen jelezve',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$bvModal.show('modal-edit')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Minden mező kitöltése kötelező',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    accepted(id) {
      axios({
        method: 'get',

        url: `order/warehouse/accept/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadOrderList(0)
        this.$bvModal.hide('modal-edit')
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .border-bottom-own{
    border-bottom: 1px solid #7a7a7a !important;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .bg-light-primary{
    background-color: #343D55 !important;
    border-radius: 10px;
  }
</style>
